export class Agente {
   idagente = "";
   idintegracion?: string;
   nombre = "";
   pass = "";
   penalidad = 0;
   canPause = false;
   canHangup = false;
   canXFER = true;
   canChangeSkill = false;
   modoAgente: EModoAgente;
   logged_on: string;
   estado_fecha: Date;
   estado_idreason: string;
   last_call: string;
   last_call_date: Date;
   //
   stats?: IStats[];

   constructor() {}
}

export interface IStats {
   cola: string;
   completeUsuario: number;
   completeAgente: number;
   completeTiempo: number;
}

export interface IAgentStatusReason {
   idreason: string;
   tipo: string;
   motivo: string;
   autor: string;
   fecha?: any;
}

export enum EModoAgente {
   NONE,
   RINGBACK,
   AUTOMATIC,
   OUTBOUND,
}

export enum EModalidadAgente {
   RINGBACK,
   AUTOMATIC,
   OUTBOUND,
}

export enum EAgentSkill {
   OUTBOUND_SKILL = "Outbound",
   INBOUND_SKILL = "Inbound",
   MIXED_SKILL = "Mixed",
}

export const OUTBOUND_SKILL = {
   MIN: 101,
   MAX: 9000000000,
};

export const INBOUND_SKILL = {
   MIN: 0,
   MAX: 10,
};
export const MIXED_SKILL = {
   MIN: 99,
   MAX: 100,
};
